<template>
    <section>
        <picture ref="banner" >
            <source media="(min-width: 768px)" width="2500" height="760" type="image/webp" srcset="~/assets/evertree/banner.webp">
            <source media="(min-width: 768px)" width="2500" height="760" type="image/jpeg" srcset="~/assets/evertree/banner.jpg">
    
            <source media="(max-width: 767px)" width="670" height="846" type="image/webp" srcset="~/assets/evertree/banner_mobile.webp">
            <source media="(max-width: 767px)" width="670" height="846" type="image/jpeg" srcset="~/assets/evertree/banner_mobile.webp">
    
            <img ref="bannerImage" alt="The Power of Your Purchase" width="2500" height="760" type="image/jpeg" src="~/assets/evertree/banner.jpg">
        </picture>
        <div class="evertreen_upper">
            <div>
                <h2 class="font-active">{{ displayedCount }}</h2>
                <h3>TREES & MORE TO GROW!</h3>
                <h4 class="font-active">The Power of Your Purchase</h4>
                <p>By making a conscious choice and choosing us, you also choose a greener planet. <br> We plant new trees for every order placed with us.</p>
                <NuxtLink :to="localePath('/green-and-sustainable-gift')">KNOW HOW</NuxtLink>
            </div>
        </div>
        <span class="upper_label">*Count Refreshed Daily</span>
    </section>
</template>
  
<script setup>
    const localePath = useLocalePath();
    const count = ref(0);
    const displayedCount = ref(0);
    const bannerImage = ref(null);
    const banner = ref(null);

    
    const getCounterValue = async () => {
    try {
      const response = await $fetch(useCountryPath('/evertreen/count'))
      if(response.success){
      count.value = response.data
      animateCounter();
      }
    }  
    catch (error) {
      console.error('Error fetching counter value:', error);
    }
    };
    
      const animateCounter = () => {
          const step = 10;
          const duration = 3000;
          const totalSteps = Math.ceil(count.value / step);
          const stepDuration = duration / totalSteps;
          let currentValue = 0;

          const incrementCounter = () => {
            if (currentValue < count.value) {
                currentValue += step;
                displayedCount.value = Math.min(currentValue, count.value);
                setTimeout(incrementCounter, stepDuration);
            } 
            else {
                displayedCount.value = count.value;
            }
          };
          incrementCounter();
      };


      const handleScroll = () => {
        const bannerTop = banner.value.getBoundingClientRect().top;
        const bannerBottom = banner.value.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;
    
        if (bannerTop < windowHeight && bannerBottom > 0) {
            bannerImage.value.style.transform = 'scale(1.1)';
        } else {
            bannerImage.value.style.transform = 'scale(1)';
        }
      };

      onMounted(() => {
        getCounterValue();
        window.addEventListener('scroll', handleScroll);
      });

      onBeforeUnmount(() => {
        window.removeEventListener('scroll', handleScroll);
      });

</script>
  
<style scoped>
  section {
    position: relative;
    text-align: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 8px
  }
  section img {
    transition: transform 1s ease;
  }
  .evertreen_upper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
  }
  h2 {
    font-size: 62px;
    letter-spacing: 5px;
    font-weight: 700;
  }
  h3 {
    font-size: 18px;
    letter-spacing: 2px;
    margin-bottom: 24px;
  }
  h4 {
    font-size: 30px;
    letter-spacing: 1px;
    /* padding: 10px 0; */
    margin-bottom: 12px;
  }
  p {
    font-size: 16px;
    margin-bottom: 24px;
  }
  a {
    border: 1px solid #fff;
    color: #fff;
    font-size: 14px;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 5px;
    letter-spacing: 2px;
  }
  span.upper_label {
    position: absolute;
    top: 15px;
    right: 25px;
    font-size: 14px;
    color: #fff;
  }
@media (max-width: 1100px) {
    h2 {
      font-size: 52px;
    }
    h3 {
      font-size: 16px;
    }
    h4 {
      font-size: 26px;
      /* padding: 0 0; */
    }
    section img {
      height: 324px;
      object-fit: cover;
    }
}
@media (max-width: 767px) {
    p br{
        display: none;
    }
    section img {
        height: auto;
    }
    .evertreen_upper {
        padding: 0 15px;
    }
    span.upper_label {
        font-size: 12px;
    }
}
</style>
  